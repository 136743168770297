export default {
  services: {
    portal: {
      available: true,
      superuser: true,
    },
    maps: {
      available: false,
      superuser: true,
    },
    stats: {
      available: false,
      superuser: true,
    },
    geoserver: {
      available: false,
      superuser: true,
    },
    geonetwork: {
      available: false,
      superuser: true,
    },
    majic: {
      available: true,
      superuser: true,
    },
  },
  datasetsListPageSize: 20,
  datasets: {
    itemsPerPage: 20,
    parentKinds: [],
  },
  logs: {
    itemsPerPage: 50,
  },
  forms: {
    user: {
      hiddenFields: [],
      requiredFields: [
        'last_name',
        'first_name',
        'email'
      ]
    },
    organisation: {
      hiddenFields: [],
      requiredFields: [
        'display_name',
        'organisation_type'
      ]
    },
    group: {
      hiddenFields: [],
      requiredFields: [
        'name'
      ]
    },
    sphere: {
      hiddenFields: [],
      requiredFields: [
        'name'
      ]
    },
    contact: {
      hiddenFields: [],
      requiredFields: [
        'name',
        'email'
      ]
    },
    dataset: {
      hiddenFields: [],
      extraFields: [],
      requiredFields: [
        'display_name',
        'description',
        'keywords',
        'topics',
        'kinds',
        'usergroup',
        'publication_date',
        'last_update_date',
        'update_frequency',
      ],
      extraFields: []
    },
    resource: {
      datastore: {
        superuserOnly: true,
        hiddenFields: [],
        requiredFields: [
          'display_name',
        ]
      },
      'file-upload': {
        superuserOnly: false,
        hiddenFields: [],
        requiredFields: [
          'display_name',
          'column_x',
          'column_y',
          'crs'
        ]
      },
      ftp: {
        superuserOnly: true,
        hiddenFields: [],
        requiredFields: [
          'display_name',
          'column_x',
          'column_y',
          'crs'
        ]
      },
      'file-download': {
        superuserOnly: false,
        hiddenFields: [],
        requiredFields: [
          'display_name',
          'column_x',
          'column_y',
          'crs'
        ]
      },
      href: {
        superuserOnly: false,
        hiddenFields: [],
        requiredFields: [
          'display_name',
        ]
      },
      geoserver: {
        superuserOnly: true,
        hiddenFields: [
        ],
        requiredFields: [
          'display_name',
        ]
      }
    },
    indexer: {
      hiddenFields: [],
      requiredFields: [
        'name',
        'url',
        'command',
        'scheduler',
      ]
    }
  },
  resourceTypes: [
    'datastore',
    'file-upload',
    'file-download',
    'ftp',
    'href',
    'geoserver'
  ],
  annexTypes: [
    'file-upload',
    'ftp',
    'href'
  ],
  wms: {
    staticBaseUrl:'https://rec.onegeosuite.fr/maps'
  }
};
