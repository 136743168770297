import resourcesAPI from '@/api/resourcesAPI.js';
import datastoreAPI from '@/api/resource/datastoreAPI.js';
import geoserverAPI from '@/api/resource/geoserverAPI';

import { ErrorService } from '@/services/error-service.js';
import miscAPI from '../../api/miscAPI';
// import { slugify } from '@/utils';
import i18n from '@/i18n';

import modules from './resource';

/**************** STATE *******************/
const state = {
  currentDatasetResources: [],

  mainResource: null,
  mainResourceData: null,
  mainResourceGeoData: null,
  mainResourceDatasetRelation: null,

  annexResources: [],
  annexResourcesDatasetRelation: [],
  annexResourcesData: [],

  lastCreatedResource: null,
  resourceDataFormats: [],
  resourceKinds: [],
  resourcesError: null,
  currentResourceId: null,
  currentResourceScenarios: {
    create: [],
    update: [],
  },
  currentPublicationScenarios: [],
  currentResourcesFilters: {
    resource_type_id: [],
    dataset_id: []
  },

  ftpChoicesLoading: false,
  ftpChoices: [],
  schedulers: []
};

/**************** GETTERS *****************/
const getters = {
  currentDatasetMainResource: (state) => {
    if (
      state.currentDatasetResources &&
      state.currentDatasetResources.length &&
      state.currentDatasetResources.find(el => el.type.value === 1)
    ) {
      return state.currentDatasetResources.find(el => el.type.value === 1);
    }
    return null;
  },
  isCurrentDatasetMainResourcePending: (state) => {
    if (
      state.currentDatasetResources &&
      state.currentDatasetResources.length &&
      state.currentDatasetResources.find(el => el.type.value === 1) &&
      state.currentDatasetResources.find(el => el.type.value === 1)._processing &&
      state.currentDatasetResources.find(el => el.type.value === 1)._processing.length &&
      state.currentDatasetResources.find(el => el.type.value === 1)._processing.some(el => el.state === 'PENDING')
    ) {
      return true;
    }
    return false;
  },
  isCurrentDatasetMainResourceFailed: (state) => {
    if (
      state.currentDatasetResources &&
      state.currentDatasetResources.length &&
      state.currentDatasetResources.find(el => el.type.value === 1) &&
      state.currentDatasetResources.find(el => el.type.value === 1)._processing &&
      state.currentDatasetResources.find(el => el.type.value === 1)._processing.length &&
      state.currentDatasetResources.find(el => el.type.value === 1)._processing.some(el => el.state === 'FAILURE')
    ) {
      return true;
    }
    return false;
  },
  currentDatasetMainResourceProcessings: (state) => {
    return state.currentDatasetResources
      .find(el => el.type.value === 1)
      ._processing;
  },
  currentDatasetAnnexResources: (state) => {
    if (
      state.currentDatasetResources &&
      state.currentDatasetResources.length &&
      state.currentDatasetResources.filter(el => el.type.value === 2)
    ) {
      return state.currentDatasetResources.filter(el => el.type.value === 2);
    }
    return [];
  },
  isCurrentDatasetAnnexResourcePending: (state) => (annexId) => {
    if (
      state.currentDatasetResources &&
      state.currentDatasetResources.length &&
      state.currentDatasetResources.find(el => el.resource.id === annexId) &&
      state.currentDatasetResources.find(el => el.resource.id === annexId)._processing &&
      state.currentDatasetResources.find(el => el.resource.id === annexId)._processing.length &&
      state.currentDatasetResources.find(el => el.resource.id === annexId)._processing.some(el => el.state === 'PENDING')
    ) {
      return true;
    } else {
      return false;
    }
  },
  currentDatasetAnnexResourceProcessings: (state) => (annexId) => {
    if (
      state.currentDatasetResources &&
      state.currentDatasetResources.find(el => el.resource.id === annexId) &&
      state.currentDatasetResources.find(el => el.resource.id === annexId)._processing
    ) {
      return state.currentDatasetResources
        .find(el => el.resource.id === annexId)
        ._processing;
    }
    return [];
  },
  isCurrentDatasetAnnexResourceFailed: (state) => (annexId) => {
    if (
      state.currentDatasetResources &&
      state.currentDatasetResources.length &&
      state.currentDatasetResources.find(el => el.resource.id === annexId) &&
      state.currentDatasetResources.find(el => el.resource.id === annexId)._processing &&
      state.currentDatasetResources.find(el => el.resource.id === annexId)._processing.length &&
      state.currentDatasetResources.find(el => el.resource.id === annexId)._processing.some(el => el.state === 'FAILURE')
    ) {
      return true;
    } else {
      return false;
    }
  },

  mainResourceRoot: (state) => {
    if (state.mainResource && state.mainResource.rel && state.mainResource.rel.length) {
      return state.mainResource.rel[0];
    }
    return null;
  },
  mainResourceRels: (state) => {
    if (state.mainResource && state.mainResource.rel && state.mainResource.rel.length) {
      return state.mainResource.rel.slice(1);
    }
    return null;
  },
  mainResourceGeographicLayer: (state) => {
    if (state.mainResource && state.mainResource.rel && state.mainResource.rel.length) {
      return state.mainResource.rel
        .find(el => el['@app'] === 'onegeo_geospatial' && el['@class'] === 'geographiclayer');
    }
    return null;
  },

  // annexResourceRoot: (state) => (annexId) => {
  //   const annex = state.annexResources.find(el => el.id === annexId);
  //   if (annex && annex.rel && annex.rel.length) {
  //     return annex.rel[0];
  //   }
  //   return null;
  // },
  // annexResourceRels: (state) => (annexId) => {
  //   const annex = state.annexResources.find(el => el.id === annexId);
  //   if (annex && annex.rel && annex.rel.length) {
  //     return annex.rel.slice(1);
  //   }
  //   return null;
  // },

  currentResourcePublishScenario: (state) => (id) => {
    const found = state.currentPublicationScenarios.find(el => el.id === id);
    if (found && found.scenarios) {
      return found.scenarios.find(el => el.codename === 'publish-resourcedataset');
    }
    return null;
  },
  currentResourceUnpublishScenario: (state) => (id) => {
    const found = state.currentPublicationScenarios.find(el => el.id === id);
    if (found && found.scenarios) {
      return found.scenarios.find(el => el.codename === 'unpublish-resourcedataset');
    }
    return null;
  },
  publishResourceScenariosFound:(state, getters) => (id) => {
    return (getters.currentResourcePublishScenario(id) && getters.currentResourcePublishScenario(id).id) &&
      (getters.currentResourceUnpublishScenario(id) && getters.currentResourceUnpublishScenario(id).id);
  },
};

/*************** MUTATIONS ****************/
const mutations = {
  SET_CURRENT_DATASET_RESOURCES: (state, payload) => {
    if (payload && payload.results) {
      state.currentDatasetResources = payload.results;
    } else {
      state.currentDatasetResources = [];
    }
  },

  SET_MAIN_RESOURCE: (state, payload) => {
    if (payload) {
      state.mainResource = { ...payload };
    } else {
      state.mainResource = null;
    }
  },

  SET_MAIN_RESOURCE_DATA: (state, payload) => {
    if (payload) {
      state.mainResourceData = payload;
    } else {
      state.mainResourceData = null;
    }
  },

  SET_MAIN_RESOURCE_GEO_DATA: (state, payload) => {
    if (payload) {
      state.mainResourceGeoData = payload;
    } else {
      state.mainResourceGeoData = null;
    }
  },

  SET_MAIN_RESOURCE_DATASET_RELATION: (state, payload) => {
    if (payload) {
      state.mainResourceDatasetRelation = payload;
    } else {
      state.mainResourceDatasetRelation = null;
    }
  },

  SET_RESOURCE_PUBLICATION_SCENARIOS: (state, payload) => {
    if (payload) {
      const index = state.currentPublicationScenarios.findIndex(el => el.id === payload.id);
      if (index === -1) {
        state.currentPublicationScenarios.push(payload);
      } else {
        state.currentPublicationScenarios[index] = payload;
      }
    }
    // TODO: ajouter nom de la ressource dans le message d'erreur
    if (
      state.currentPublicationScenarios
        .some(el => !el.scenarios.find(sce => sce.codename === 'publish-resourcedataset'))
    ) {
      ErrorService.onError(null, 'Scénario de publication de la ressource manquant.');
    }
    if (
      state.currentPublicationScenarios
        .some(el => !el.scenarios.find(sce => sce.codename === 'unpublish-resourcedataset'))
    ) {
      ErrorService.onError(null, 'Scénario de dépublication de la ressource manquant.');
    }
  },

  SET_ANNEX_RESOURCE: (state, payload) => {
    if (payload) {
      const index = state.annexResources.findIndex(el => el.id === payload.id);
      if (index === -1) {
        state.annexResources.push(payload);
      }
    } else {
      state.annexResources.splice(0);
    }
  },

  SET_ANNEX_DATASET_RELATION: (state, payload) => {
    if (payload) {
      const index = state.annexResourcesDatasetRelation.findIndex(el => el.id === payload.id);
      if (index === -1) {
        state.annexResourcesDatasetRelation.push(payload);
      }
    } else {
      state.annexResourcesDatasetRelation.splice(0);
    }
  },

  SET_RESOURCE_DATA_FORMATS: (state, payload) => {
    if (payload) {
      state.resourceDataFormats = payload;
    }
  },

  SET_RESOURCE_KINDS: (state, payload) => {
    if (payload) {
      state.resourceKinds = payload;
    }
  },

  SET_FTP_CHOICES: (state, payload) => {
    if (payload) {
      state.ftpChoices = payload;
    } else {
      state.ftpChoices = [];
    }
  },
  SET_FTP_CHOICES_LOADING: (state, payload) => {
    state.ftpChoicesLoading = payload;
  },

  SET_CURRENT_RESOURCE_ID: (state, payload) => {
    state.currentResourceId = payload;
  },

  SET_CURRENT_RESOURCE_SCENARIOS: (state, payload) => {
    if (payload && Array.isArray(payload)) {
      state.currentResourceScenarios.create = payload.filter(el => el.action === 'create');
      state.currentResourceScenarios.update = payload.filter(el => el.action === 'update');
    }
  },
  RESET_CURRENT_RESOURCE_SCENARIOS: (state) => {
    state.currentResourceScenarios = {
      create: [],
      update: [],
    };
  },

  SET_RESOURCES_FILTERS: (state, payload) => {
    if (state.currentResourcesFilters[payload.filter].findIndex(el => el === payload.value) === -1) {
      if (payload.filter === 'dataset_id') {
        state.currentResourcesFilters[payload.filter] = [payload.value];
      } else {
        state.currentResourcesFilters[payload.filter].push(payload.value);
      }
    }
  },

  REMOVE_RESOURCES_FILTERS: (state, payload) => {
    const index = state.currentResourcesFilters[payload.filter].findIndex(el => el === payload.value);
    if (index !== -1) {
      state.currentResourcesFilters[payload.filter].splice(index, 1);
    }
  },

  SET_LAST_CREATED_RESOURCE: (state, payload) => {
    state.lastCreatedResource = payload;
  },

  SET_ERROR: (state, error) => {
    if (error) {
      ErrorService.onError(error);
    }
    state.resourcesError = error;
  },

  SET_SCHEDULERS: (state, payload) => {
    if (payload.results) {
      state.schedulers = [...payload.results];
    }
  }
};
/**************** ACTIONS *****************/
const actions = {
  GET_CURRENT_DATASET_RESOURCES: async ({ commit }, datasetId) => {
    const resources = await resourcesAPI.getDatasetResources(datasetId);
    commit('SET_CURRENT_DATASET_RESOURCES', resources);
  },

  GET_MAIN_RESOURCE: async ({ getters, commit, dispatch }, { id, resourceToDatasetId }) => {
    const resource = await resourcesAPI.getResource(id);
    commit('SET_MAIN_RESOURCE', resource);
    if (getters.mainResourceRoot && getters.mainResourceRoot['@uri']) {
      const resourceData = await resourcesAPI.getResourceData(getters.mainResourceRoot['@uri']);
      commit('SET_MAIN_RESOURCE_DATA', resourceData);
    }
    if (resourceToDatasetId) {
      await dispatch('GET_RESOURCE_DATASET_RELATION', resourceToDatasetId);
    }
  },

  GET_RESOURCE_DATASET_RELATION: async ({ commit }, resourceToDatasetId) => {
    const publicationScenarios = await resourcesAPI.getResourceToDatasetScenarios(resourceToDatasetId);
    commit('SET_RESOURCE_PUBLICATION_SCENARIOS', {
      id: resourceToDatasetId,
      scenarios: publicationScenarios
    });
    const resourceToDataset = await resourcesAPI.getResourceDatasetRelation(resourceToDatasetId);
    commit('SET_MAIN_RESOURCE_DATASET_RELATION', resourceToDataset);
  },

  GET_ANNEX_RESOURCE: async ({ commit, dispatch }, { id, resourceToDatasetId }) => {
    const annex = await resourcesAPI.getResource(id);
    commit('SET_ANNEX_RESOURCE', annex);
    if (resourceToDatasetId) {
      await dispatch('GET_ANNEX_DATASET_RELATION', resourceToDatasetId);
    }
  },
  GET_ANNEX_DATASET_RELATION: async ({ commit }, resourceToDatasetId) => {
    const publicationScenarios = await resourcesAPI.getResourceToDatasetScenarios(resourceToDatasetId);
    commit('SET_RESOURCE_PUBLICATION_SCENARIOS', {
      id: resourceToDatasetId,
      scenarios: publicationScenarios
    });
    const resourceToDataset = await resourcesAPI.getResourceDatasetRelation(resourceToDatasetId);
    commit('SET_ANNEX_DATASET_RELATION', resourceToDataset);
  },

  PATCH_RESOURCE: async (context, { id, data }) => {
    await resourcesAPI.patchResource(id, data);
  },

  GET_RESOURCE_DATA_FORMATS: async ({ commit }) => {
    const formats = await resourcesAPI.getResourceDataFormats();
    commit('SET_RESOURCE_DATA_FORMATS', formats);
  },

  GET_RESOURCE_KINDS: async ({ commit }) => {
    const kinds = await resourcesAPI.getResourceKinds();
    commit('SET_RESOURCE_KINDS', kinds);
  },

  GET_FTP_CHOICES: async ({ commit }, usergroupId) => {
    commit('SET_FTP_CHOICES_LOADING', true);
    const response = await resourcesAPI.getFTPChoices(usergroupId);
    const ftpChoices = response.map(el => { return { ...el, checked: false }; });
    ftpChoices.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    commit('SET_FTP_CHOICES', ftpChoices);
    commit('SET_FTP_CHOICES_LOADING', false);
  },

  GET_RESOURCE_SCENARIOS: async ({ commit }, { slug, resourceId }) => {
    const scenarios = await resourcesAPI.getResourceScenarios(slug, resourceId);
    if (scenarios && scenarios.length) {
      commit('SET_CURRENT_RESOURCE_SCENARIOS', scenarios);
    } else {
      ErrorService(null, i18n.messages[i18n.locale].messages.error.scenario.none);
    }
  },

  START_CREATE_RESOURCE: async (
    { commit, dispatch },
    {
      slug,
      postResourceData,
      resourceCreationData,
      datasetId,
      type,
      datastoreType,
      layerType
    }
  ) => {
    try {      
      const createdResource = await resourcesAPI.createResource(postResourceData);
      commit('SET_LAST_CREATED_RESOURCE', createdResource);
      await resourcesAPI.createResourceDatasetRelation({
        resource_id: createdResource.id,
        dataset_id: datasetId,
        type: type
      });

      // add resourceId to data creation payload
      resourceCreationData.resource_id = createdResource.id;
      const createdDataId = await dispatch(
        `${slug}/START_CREATE_DATA`,
        {
          data: resourceCreationData,
          datastoreType: datastoreType,
          layerType: layerType
        }
      );
      if (createdDataId) {
        await dispatch('GET_RESOURCE_SCENARIOS', {
          slug: slug,
          resourceId: createdDataId
        });
      }
    } catch(err) {
      console.error(err);
      commit('SET_LAST_CREATED_RESOURCE', null);
      commit('modal/CLOSE_MODAL', 'progress', { root: true });
      commit('SET_PROGRESS_PERCENTAGE', 0, { root: true });
      commit('RESET_CURRENT_RESOURCE_SCENARIOS');
      commit('SET_ERROR', err);
    }
  },
  FINISH_CREATE_RESOURCE: async (
    { commit, dispatch },
    { typeResource, scenarioId, data, /*scheduler,*/ datastoreType, layerType }
  ) => {
    try {
      // let schedulerData;
      // if (scheduler) {
      //   const schedulerName = `Mise à jour ${scheduler.label} - ${createdResource.resource.codename}`;
      //   schedulerData = {
      //     codename: slugify(schedulerName),
      //     display_name: schedulerName,
      //     core_scenario_id: action,
      //     scheduler: {
      //       class_id: scheduler.class_id,
      //       id: scheduler.id
      //     },
      //     kwargs: {
      //       dataset_id: data.kwargs.dataset_id
      //     }
      //   };
      //   await resourcesAPI.setFTPScheduler(state.currentResourceId, schedulerData);
      // }

      dispatch(
        `${typeResource}/FINISH_CREATE_DATA`,
        {
          scenarioId: scenarioId,
          data: data,
          datastoreType: datastoreType,
          layerType: layerType
        }
      );
      commit('SET_LAST_CREATED_RESOURCE', null);
      commit('RESET_CURRENT_RESOURCE_SCENARIOS');
    } catch (err) {
      commit('SET_LAST_CREATED_RESOURCE', null);
      commit('RESET_CURRENT_RESOURCE_SCENARIOS');
      commit('SET_ERROR', err);
    }
  },

  START_MODIFY_RESOURCE: async ({ commit, dispatch }, {
    typeResource,
    data,
    resourceId,
    datastoreType
  }) => {
    try {
      // add resourceId to data creation payload
      data.resource_id = resourceId;
      const updatedResourceId = await dispatch(
        `${typeResource}/START_MODIFY_DATA`,
        {
          data: data,
          datastoreType: datastoreType
        }
      );
      if (updatedResourceId) {
        await dispatch('GET_RESOURCE_SCENARIOS', {
          slug: typeResource,
          resourceId: updatedResourceId
        });
      }
    } catch(err) {
      commit('modal/CLOSE_MODAL', 'progress', { root: true });
      commit('SET_PROGRESS_PERCENTAGE', 0, { root: true });
      commit('SET_ERROR', err);
      throw new Error(err);
    }
  },
  FINISH_MODIFY_RESOURCE: async (
    { commit, dispatch },
    { typeResource, scenarioId, data, datastoreType, datastoreId }
  ) => {
    try {
      await dispatch(
        `${typeResource}/FINISH_MODIFY_DATA`,
        {
          scenarioId: scenarioId,
          data: data,
          datastoreType: datastoreType,
          datastoreId: datastoreId
        }
      );
      commit('RESET_CURRENT_RESOURCE_SCENARIOS');
    } catch(err) {
      console.error(err);
      commit('RESET_CURRENT_RESOURCE_SCENARIOS');
      commit('SET_ERROR', err);
    }
  },

  START_UPDATE_RESOURCE: async (
    { commit },
    { typeResource, data, datastoreType, layerType }
  ) => {
    try {
      // Get resource scenarios
      let scenarios = null;
      if (typeResource === 'datastore') {
        scenarios = await datastoreAPI.getDatastoreResourceScenarios(datastoreType, data.id);
      } else if (typeResource === 'geoserver') {
        scenarios = await geoserverAPI.getDatastoreResourceScenarios(layerType, data.id);
      }
      if (scenarios && scenarios.length) {
        commit('resources/SET_CURRENT_RESOURCE_SCENARIOS', scenarios, { root: true });
      } else {
        ErrorService(null, i18n.messages[i18n.locale].messages.error.scenario.none);
      }
    } catch(err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
  },

  FINISH_UPDATE_RESOURCE: async (
    { commit },
    { type, typeResource, action, datastoreType, datastoreId, kwargs }
  ) => {
    try {
      if (type === 'resource') {
        if (typeResource === 'datastore') {
          // Run scenario
          await datastoreAPI.runDatastoreResourceScenario(datastoreType, datastoreId, action, { kwargs: kwargs });
          ErrorService.onSuccess(
            true,
            'La mise à jour de la ressource a été lancée avec succès.'
          );
        }
      }
      commit('RESET_CURRENT_RESOURCE_SCENARIOS');
    } catch(err) {
      console.error(err);
      commit('RESET_CURRENT_RESOURCE_SCENARIOS');
      commit('SET_ERROR', err);
    }
  },

  START_CREATE_ANNEXE: async ({ state, commit, dispatch }, { slug, data }) => {
    try {
      const createdAnnexeId = await dispatch(
        `${slug}/START_CREATE_ANNEXE`,
        { data: data }
      );
      if (createdAnnexeId) {
        await dispatch('GET_RESOURCE_SCENARIOS', {
          slug: slug,
          resourceId: createdAnnexeId
        });

        dispatch('FINISH_CREATE_ANNEXE', {
          slug: slug,
          scenarioId: state.currentResourceScenarios.create[0].id,
          data: data
        });
      }
    } catch (err) {
      commit('modal/CLOSE_MODAL', 'progress', { root: true });
      commit('SET_PROGRESS_PERCENTAGE', 0, { root: true });
      commit('RESET_CURRENT_RESOURCE_SCENARIOS');
      console.error(err);
      commit('SET_ERROR', err);
    }
  },
  FINISH_CREATE_ANNEXE: async ({ commit, dispatch }, { slug, scenarioId, data }) => {
    try {
      await dispatch(
        `${slug}/FINISH_CREATE_ANNEXE`,
        {
          scenarioId: scenarioId,
          data: data,
        }
      );
      commit('RESET_CURRENT_RESOURCE_SCENARIOS');
    } catch (err) {
      console.error(err);
      commit('SET_ERROR', err);
    }
  },

  DELETE_RESOURCE_FROM_DATASET: async ({ commit }, { resourceToDatasetId, resource }) => {
    try {
      await resourcesAPI.deleteResourceToDataset(resourceToDatasetId);
      // await resourcesAPI.deleteResource(resource.id);
      commit('SET_ERROR', null);
      ErrorService.onSuccess(
        true,
        i18n.messages[i18n.locale].messages.success.resource.removed
          .replace('{name}', resource && resource.display_name ? resource.display_name : ''),
      );
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  DELETE_RESOURCE: async ({ commit }, resource) => {
    try {
      await resourcesAPI.deleteResource(resource.id);
      commit('SET_ERROR', null);
      ErrorService.onSuccess(
        true,
        i18n.messages[i18n.locale].messages.success.resource.deleted
          .replace('{name}', resource && resource.display_name ? resource.display_name : ''),
      );
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  GET_SCHEDULERS: async ({ commit }) => {
    const schedulers = await miscAPI.getSchedulers();
    commit('SET_SCHEDULERS', schedulers);
  }
};

export default {
  namespaced: true,
  modules,
  state,
  getters,
  actions,
  mutations,
};
