import axios from 'axios';
import i18n from '@/i18n';
import { ErrorService } from '@/services/error-service.js';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const DOMAIN = process.env.VUE_APP_DOMAIN;
const GEOSERV_REST_API_PATH = '/geoserv/rest/workspaces';
const GEOSERV_MIRROR_API_PATH = '/geoserv/mirror/';

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const geoserverAPI = {

  /** GET */
  async getWorkspaces() {
    try {
      const url = new URL(`${i18n.locale}${GEOSERV_REST_API_PATH}.json`, DOMAIN);
      const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch(err) {
      console.error(err);
    }
  },
  async getWorkspaceLayers(workspace) {
    try {
      const url = new URL(`${i18n.locale}${GEOSERV_REST_API_PATH}/${workspace}/layers.json`, DOMAIN);
      const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch(err) {
      console.error(err);
    }
  },
  async getWorkspaceLayergroups(workspace) {
    try {
      const url = new URL(`${i18n.locale}${GEOSERV_REST_API_PATH}/${workspace}/layergroups.json`, DOMAIN);
      const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch(err) {
      console.error(err);
    }
  },

  /** CREATE */
  async createGeoserverSource(data) {
    try {
      let name;
      if (data['layer'] !== null) {
        name = `${data.workspace.name}:${data['layer'].name}`;
      }
      else if (data['layergroup'] !== null) {
        name = `${data.workspace.name}:${data['layergroup'].name}`;
      }
      const url = new URL(`${i18n.locale}${GEOSERV_MIRROR_API_PATH}${data.layerType}/`, DOMAIN);
      const response = await axios.post(url, { name: name }, { ...DEV_AUTH && { auth: AUTH } });
      if ([201, 200].includes(response.status)) {
        return response.data;
      }
      return false;
    } catch(err) {
      ErrorService.onError(err);
      return false;
    }
  },

  /** UPDATE */
  async updateGeoservResource(id, type, data, async = true) {
    const url = new URL(
      `${i18n.locale}${GEOSERV_MIRROR_API_PATH}${type}/${id}/?asynchrone=${async}`,
      DOMAIN
    );
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  /** SCENARII */
  async getGeoserverResourceScenarios(type, id) {
    const url = new URL(
      `${i18n.locale}${GEOSERV_MIRROR_API_PATH}${type}/${id}/_scenarios/`,
      DOMAIN
    );
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async runGeoserverResourceScenario(type, id, scenarioId, data) {
    const url = new URL(
      `${i18n.locale}${GEOSERV_MIRROR_API_PATH}${type}/${id}/_scenarios/${scenarioId}/run/?asynchrone=true`,
      DOMAIN
    );
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 202) {
      return response.data;
    }
    return false;
  },
};

export default geoserverAPI;